import api from '@services/api';
import { RoleEnum } from '@types';
import { useDebounce, useUpdateEffect } from 'ahooks';
import { Button, Input, Select } from 'antd';
import { FC, useState } from 'react';
import { useQuery } from '../../../hooks/useQuery';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';

type Props = {
  onSearch: (e: string) => void;
  onAdd?: () => void;
  onSelectStatus?: (e: string) => void;
  totalRecords: number;
};
export const FilterApps: FC<Props> = ({ onSearch, onAdd, onSelectStatus, totalRecords }) => {
  const query = useQuery();
  const search = query?.get('search');
  const [searchKey, setSearchKey] = useState<string>(search ? search : '');
  const debouncedValue = useDebounce(searchKey, { wait: 500 });
  const [statusKey, setSelectStatus] = useState<string>('ALL');
  const userData = api.getAuthenticated();

  useUpdateEffect(() => {
    onSearch?.(debouncedValue);
  }, [debouncedValue]);

  const onHandleSelectStatus = (e: string) => {
    onSelectStatus?.(e.toUpperCase());
    setSelectStatus(e.toUpperCase());
  };

  return (
    <>
      <div className="button-add">
        {userData.role != RoleEnum.ROOT && onAdd && (
          <Button type="primary" onClick={onAdd}>
            <PlusCircleOutlined />Add
          </Button>
        )}
      </div>
      <div className="filter-container">
        <div className="filter-apps">
          <div className="search_app-id">
            <Input
              placeholder="Enter search app name"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              suffix={<SearchOutlined />}
              variant="outlined"
            />
          </div>
          <div className="other-filter_apps">
            <div className="other-filter__apps-item">
              <span>Status</span>
              <div className="select-filter">
                <Select
                  defaultValue={statusKey}
                  style={{ width: 120 }}
                  onChange={(e) => onHandleSelectStatus(e)}
                  options={
                    userData.role == RoleEnum.ROOT
                      ? [
                          { value: 'ALL', label: 'All' },
                          { value: 'active', label: 'Active' },
                          { value: 'deleted', label: 'Deleted' },
                          { value: 'unlisted', label: 'Unlisted' },
                        ]
                      : [
                          { value: 'ALL', label: 'All' },
                          { value: 'true', label: 'Active' },
                          { value: 'false', label: 'Inactive' },
                        ]
                  }
                />
              </div>
            </div>
            <div>
              <span>Total Records:</span> <span>{totalRecords}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
